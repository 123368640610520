import Navber from "../navber/Navber"
import "./layout.css"

export default function Layout({ children }) {
    return (
        <div>
            <Navber />
            <div className="main-content">
                {children}
            </div>
        </div>
    )
}
