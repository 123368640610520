import React from 'react'
import "./navber.css"

export default function Navber() {
    return (
        <div className="nav-wrapper">
            <div className="nav-container">
                <div className="nav-logo"><img src="./datacurve.png" alt="" /></div>
                <div></div>
            </div>
        </div>
    )
}
