import React, { useState } from "react";

function UploadImage({ onSend }) {
  const [text, setText] = useState("");

  const handleInputChange = (e) => {
    setText(e.target.files[0]);
  };

  const handleSend = (e) => {
    e.preventDefault();
    onSend(text);
    setText("");
  };

  return (
    <div className="input">
      <form className="UploadForm" onSubmit={handleSend}>
        <div >
          <input type="file" onChange={handleInputChange} />
        </div>
        <button>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 500 500"
          >
            <g>
              <g>
                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
              </g>
            </g>
          </svg>
        </button>
      </form>
    </div>
  );
}
export default UploadImage;
